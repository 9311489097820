import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";

import { AuthService } from "../auth.service";

export const USER_ROLE = "user";
export const ADMIN_ROLE = "admin";

@Injectable({
  providedIn: "root"
})
export class RoleGuard implements CanActivate {
  profileinfo: any;
  adminRoutes = [
    "user-list",
    "view-profile/:id",
    "admin/previous/:version/:userId",
    "view/admin/previous/:version/:userId",
    "view/admin/:userId",
    "admin/:userId"
  ];
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (
      this.authService.isAuthenticated() &&
      this.authService.getRole() === ADMIN_ROLE
    ) {
      if (this.adminRoutes.findIndex(item => item === route.routeConfig.path)!== -1) return true;
      else {
        this.router.navigate(["admin/user-list"]);
        return false;
      }
    } else if (
      this.authService.isAuthenticated() &&
      this.authService.getRole() === USER_ROLE
    ) {
      if (this.adminRoutes.findIndex(item => item === route.routeConfig.path)=== -1) return true;
      else {
        this.router.navigate(["dashboard"]);
        return false;
      }
    } else {
      this.router.navigate([""], {
        replaceUrl: true
      });
      return false;
    }
  }
}

