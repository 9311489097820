import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthService } from "../../auth/auth.service";
import Swal from "sweetalert2";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        //check if status code 403 also needs to be added
        if (err.status === 401) {
          Swal.fire({
            title: "Session expired. Please login again",
            showCancelButton: false,
            confirmButtonColor: "#4d74e8",
            confirmButtonText: "OK",
            allowOutsideClick: false
          }).then(result => {
            if (result.value) {
              this.authService.logOut();
            }
          });
        } 
        return this.handleResponseError(err.error);
      })
    );
  }

  handleResponseError(error: any) {
    if (error && error.message && error.message.length > 0) {
      //this.utils.showToaster(NOTIFIER_TYPE.ERROR, error.message_list[0]);
    } else if (error.message) {
      // this.utils.showToaster(NOTIFIER_TYPE.ERROR, error.message);
    }
    return throwError(error);
  }
}
