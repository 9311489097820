import { Injectable } from "@angular/core";
import { Router, CanLoad } from "@angular/router";

import { AuthService } from "../auth.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanLoad {
  constructor(private router: Router, private authService: AuthService) {}

  canLoad(): boolean {
    if (this.authService.isAuthenticated() && !!this.authService.getCookie("token")) {
      return true;
    } else {
      this.authService.clear();
      var redirectURL = window.location.href;
      //window.location.href = "https://sso.bcta.qburst.build/sso/login";
      window.location.href = `https://sso.businesscalltoaction.org/auth/login?ref=${redirectURL}`;
      return false;
    }
  }
}
