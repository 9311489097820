import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  apiUrl = 'api/v1'
  constructor(private authService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken = `${this.authService.getToken()}`;
    request = request.clone({
     // url: environment.serverBaseUrl + this.apiUrl + request.url,
      url: this.urlGenerator(request.url),
      setHeaders: {
        Authorization: authToken,
      },
    });

    return next.handle(request);
  }
  urlGenerator(requestUrl){
    if(requestUrl.indexOf('media/pdf_file/') > -1) return environment.serverBaseUrl + requestUrl
    else return environment.serverBaseUrl + this.apiUrl + requestUrl;
  }
}
