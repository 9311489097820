import {
  Component,
  OnInit,
  HostListener,
  Output,
  EventEmitter
} from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "src/app/auth/auth.service";
import { SCREEN_WIDTH } from '../../core/constants'

@Component({
  selector: "app-top-nav-bar",
  templateUrl: "./top-nav-bar.component.html",
  styleUrls: ["./top-nav-bar.component.scss"]
})
export class TopNavBarComponent implements OnInit {
  @Output() userProfileInfo = new EventEmitter();
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth <= SCREEN_WIDTH) {
      this.isMenuOpen = false;
      this.isSubMenuOpen = false;
    } else {
      this.isMenuOpen = true;
      this.isSubMenuOpen = true;
    }
  }

  routerUrl: any;
  isMenuOpen: boolean;
  isSubMenuOpen: boolean;
  profileInfo;
  userRole: string;
  constructor(private authService: AuthService, private router: Router) {
    this.isMenuOpen = true;
    this.isSubMenuOpen = true;
  }

  ngOnInit() {
    this.userRole = this.authService.getRole();
    this.routerUrl = this.router.url;
    if (window.innerWidth <= SCREEN_WIDTH) {
      this.isMenuOpen = false;
      this.isSubMenuOpen = false;
    } else {
      this.isMenuOpen = true;
      this.isSubMenuOpen = true;
    }
    this.getProfileInfo();
  }
  menuHandler() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  subMenuHandler() {
    this.isSubMenuOpen = !this.isSubMenuOpen;
  }
  async getProfileInfo() {
    await this.authService.getProfileInfo().subscribe(data => {
      this.profileInfo = data ? data : null;
      this.userProfileInfo.emit(this.profileInfo);
    });
  }
  subMenuConfig() {
    if (window.innerWidth <= SCREEN_WIDTH) {
      this.isMenuOpen = false;
      this.isSubMenuOpen = false;
    } else {
      this.isMenuOpen = true;
      this.isSubMenuOpen = true;
    }
  }
  logOut() {
    this.authService.logOut();
  }
}
