import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

import { BaseAPIClass } from '../core/class/baseAPI.class';
import { Router } from '@angular/router';

export const AUTH_TOKEN = "token";
export const USER_ROLE = "role";
@Injectable({
  providedIn: "root"
})
export class AuthService {
  headerRoutes: Array<string> = ['/', '/case-studies', '/typology'];
  headerValue: boolean = true;

  constructor(private apiService: BaseAPIClass, private router: Router) {
  }

  getToken() {
    return localStorage.getItem(AUTH_TOKEN);
  }
  setToken(value) {
    localStorage.setItem(AUTH_TOKEN, value);
  }
  getRole() {
    return localStorage.getItem(USER_ROLE);
  }
  setRole(value) {
    localStorage.setItem(USER_ROLE, value);
  }
  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(";");
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, "");
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return "";
  }
  isAuthenticated(): boolean {
    return !!this.getToken();
  }
  clear() {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USER_ROLE);
  }

  logOut(status?) {
    this.clear();
    if(!(status && status == 500)) {
      window.location.href = 'https://sso.businesscalltoaction.org/sso/logout';
    } else {
      this.router.navigate(["/"]);
    }
  }

  getProfileInfo(){
    return this.apiService.get(`/user/profile-info`).pipe(map(response => {
      return response.data;
    }));
  }
  userAuthentication(){
   return this.apiService.create(`/user/user-authentication`).pipe(map(response =>{
      return response.data;
    }))
  }
}
