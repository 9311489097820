import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-success-toaster',
  templateUrl: './success-toaster.component.html',
  styleUrls: ['./success-toaster.component.scss']
})
export class SuccessToasterComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private snackbar: MatSnackBar) { }

  ngOnInit() {
  }

  dismiss(){
    this.snackbar.dismiss();
  }
}
