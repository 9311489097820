import { Component } from "@angular/core";
import { Router, Event} from "@angular/router";
import { AuthService } from "./auth/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "angular8-boilerplate";
  headerRoutes: Array<string> = [
    "/",
    "/case-studies",
    "/case-studies/:id",
    "/typology"
  ];
  homeHeader: boolean = true;

  constructor(private router: Router, private authService: AuthService) {
    router.events.subscribe((event: Event) => {
      this.headerRoutes.indexOf(this.router.url) > -1 ||
        this.router.url.indexOf("/case-studies") !== -1
        ? (this.homeHeader = true)
        : (this.homeHeader = false);
    });

    if (!this.authService.isAuthenticated() && !!this.authService.getCookie("token")) {
      let token = this.authService.getCookie("token");
      let redirectUrl = this.authService.getCookie("redirect_url");
      if (token) {
        this.authService.setToken(token);
        this.authService.userAuthentication().subscribe(
          data => {
            //user authentication
          },
          err => {
            // when user authentication fails
            this.authService.logOut(err.status);
           },
          () => {
            this.authService.getProfileInfo().subscribe(profileInfo => {
              if (profileInfo) {
                if (profileInfo && profileInfo.role === "admin") {
                  this.authService.setRole(profileInfo.role);
                  this.router.navigate(["/admin/user-list"]);
                } else {
                  this.authService.setRole(profileInfo.role);
                  let url = redirectUrl.replace(/["]+/g, '');
                  window.location.href = url;
                }
              }
            });
          }
        );
      }
    } else if (this.authService.isAuthenticated() && !this.authService.getCookie("token")) {
      this.authService.logOut();
    }
  }
}
