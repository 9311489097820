import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { FooterComponent } from './footer/footer.component';
import { ToolProgressComponent } from '../tool-progress/tool-progress.component';
import { LoaderComponent } from './loader/loader.component';
import { TopNavBarComponent } from './top-nav-bar/top-nav-bar.component';
import { MenuComponent } from './menu/menu.component';
import { SuccessToasterComponent } from './success-toaster/success-toaster.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  imports: [ CommonModule, FormsModule, RouterModule, MatSnackBarModule, ReactiveFormsModule, MatTabsModule, MatRadioModule, MatButtonModule,
    MatProgressBarModule, MatDialogModule ],
  declarations: [
    MenuComponent,
    SuccessToasterComponent,
    FooterComponent,
    ToolProgressComponent,
    LoaderComponent,
    TopNavBarComponent,
    SuccessToasterComponent,
    HeaderComponent,
  ],
  exports: [
    MatTabsModule,
    MatRadioModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatDialogModule,
    FooterComponent,
    MenuComponent,
    ToolProgressComponent,
    SuccessToasterComponent,
    LoaderComponent,
    HeaderComponent,
    TopNavBarComponent
  ],
  entryComponents: [SuccessToasterComponent] 
})
export class SharedModule {}
