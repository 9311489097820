import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map , catchError} from 'rxjs/operators';
export const AUTH_TOKEN = "token";
export const USER_ROLE = "role";
@Injectable({
  providedIn: 'root'
})
export abstract class BaseAPIClass {

  constructor(protected httpClient: HttpClient) {}

  get(path: string, params?: any): Observable<any> {
    return this.httpClient.get(`${path}`, { params})
      .pipe(
      map((body: any) => {
        return body;
      })
      )
    .pipe(
      catchError((body: any) => {
        if(body.status == 500 && body.error.message[0] == 'User does not exist'){
          localStorage.removeItem(AUTH_TOKEN);
          localStorage.removeItem(USER_ROLE);
          window.location.href = 'https://sso.businesscalltoaction.org/sso/logout';
        }
        return body
      })

    );
  }

  create(path: string, payload?: any): Observable<any> {
    return this.httpClient.post(`${path}`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  update(path: string, payload: any): Observable<any> {
    return this.httpClient.put(`${path}`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  delete(path: string, id: string): Observable<any> {
    return this.httpClient.delete(`${path}/${id}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  deleteAll(path: string): Observable<any> {
    return this.httpClient.delete(`${path}/all`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
  
  fileDownload(path: string, params?: any): Observable<any> {
    return this.httpClient.get(`${path}`, { params, responseType: 'blob' })
      .pipe(
      map((body: any) => {
        return body;
      })
    );
  }
}
