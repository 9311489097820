import { Component, OnInit, Input } from "@angular/core";
import { ToolProgressService } from "./tool-progress.service";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-tool-progress",
  templateUrl: "./tool-progress.component.html",
  styleUrls: ["./tool-progress.component.scss"]
})
export class ToolProgressComponent implements OnInit {
  @Input("toolProgress")
  set progressStatus(toolProgress) {
    if (toolProgress) {
      this.toolProgress = toolProgress;
    }
  }
  @Input() isLanding: boolean;
  @Input() versionId: number;

  progressData: any;
  toolProgress: string;
  breadcrumbText: string;
  isComplete: boolean;

  constructor(
    private toolProgressService: ToolProgressService,
    private authService: AuthService,
    private router: Router
  ) {
    this.isLanding = false;
    this.isComplete = false;
    if (this.router.url.indexOf("/assess") !== -1) {
      this.breadcrumbText = "assess";
    } else if (this.router.url.indexOf("/compare") !== -1) {
      this.breadcrumbText = "compare";
    } else {
      this.breadcrumbText = "plan";
    }
  }

  ngOnInit() {
    if (this.authService.getRole() === "admin") {
      this.isComplete = true;
    } else {
      this.isComplete = false;
      this.getProgress();
    }
  }
  getProgress() {
    this.toolProgressService.getProgress().subscribe(data => {
      this.progressData = data ? data : null;
      if (this.progressData) {
        const {
          assess_completed,
          compare_completed,
          plan_completed
        } = this.progressData;
        if (assess_completed && compare_completed && plan_completed) {
          this.isComplete = true;
        } else if (this.versionId) {
          this.isComplete = true;
        }
      }
    });
  }
}
