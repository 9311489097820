export const YAXES_LABELS: Object = {
  0: "",
  1: "Aligned Leadership and Accountability",
  2: "Risk and Opportunities Management",
  3: "Stakeholder Engagement and Collaboration",
  4: "Corporate Advocacy",
  5: "Performance Management",
  6: "Target Setting",
  7: "Talent Recruitment and Management",
  8: "Employee Wellbeing",
  9: "R&D Practices",
  10: "Adoption of Innovative Technology"
}

export const SCREEN_WIDTH: number = 768;
