import {
  Component,
  OnInit,
  HostListener
} from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "src/app/auth/auth.service";
import { SCREEN_WIDTH } from '../../core/constants'


@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"]
})
export class MenuComponent implements OnInit {
  isMenuOpen: boolean = true;
  loggedInUser: boolean = false;
  isSubMenuOpen: boolean = true;
  profileInfo;
  routerUrl: any;
  userRole: string;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth <= SCREEN_WIDTH) {
      this.isMenuOpen = false;
      this.isSubMenuOpen = false;
    } else {
      this.isMenuOpen = true;
    }
  }

  constructor(private authService: AuthService, private router: Router) {
    router.events.subscribe((val) => {
      this.loggedInUser = this.authService.isAuthenticated();
    })
  }

  ngOnInit() {
    this.userRole = this.authService.getRole();
    this.routerUrl = this.router.url;
    if(window.innerWidth <= SCREEN_WIDTH) {
      this.isMenuOpen = false;
      this.isSubMenuOpen = false;
    } else {
      this.isMenuOpen = true;
      this.isSubMenuOpen = true;
    }
    this.loggedInUser = this.authService.isAuthenticated();
    this.getProfileInfo();
  }
  getProfileInfo() {
    if(this.authService.isAuthenticated())
    this.authService.getProfileInfo().subscribe(data=>{
      this.profileInfo = data ? data: null;
    })
  }
  menuHandler() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  subMenuHandler() {
    this.isSubMenuOpen = !this.isSubMenuOpen;
  }
  subMenuConfig(){
    if(window.innerWidth <= SCREEN_WIDTH) {
      this.isMenuOpen = false;
      this.isSubMenuOpen = false;
    } else {
      this.isMenuOpen = true;
      this.isSubMenuOpen = true;
    }
  }
  logOut() {
    this.authService.logOut();
  }
}
