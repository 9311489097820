import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})

export class LoaderComponent {
  showLoader: boolean = false;
  @Input()
  set show(loading: boolean) {
    this.showLoader = loading;
  }

  @Input() fullLoader: boolean;

  get show(): boolean { return this.showLoader; }

  constructor() { 
  }

}
