import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { BaseAPIClass } from '../core/class/baseAPI.class';

@Injectable({
  providedIn: 'root'
})
export class ToolProgressService {

  constructor(private apiService: BaseAPIClass) { }

  getProgress() {
    return this.apiService.get(`/inclusive-business/progress`).pipe(map(response => {
      return response.data;
    }));
  }
}
