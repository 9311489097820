import { Routes } from "@angular/router";

import { AuthGuard } from "./auth/guards";

export const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import("./home/home.module").then(m => m.HomeModule),
  },
  {
    path: 'plan',
    canLoad: [AuthGuard],
    loadChildren: () => import('./plan/plan.module').then(m => m.PlanModule)
  },
  {
    path: 'profile',
    canLoad: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'admin',
    canLoad: [AuthGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'assess',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./assess/assess.module').then(m => m.AssessModule)
  },
  {
    path: 'compare',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./compare/compare.module').then(m => m.CompareModule)
  },
  {
    path: 'dashboard',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("./landing-page/landing-page.module").then(
        m => m.LandingPageModule
      )
  },
  {
    path: 'typology',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./typology/typology.module').then(m => m.TypologyModule)
  },
  {
    path: "**",
    redirectTo: "/"
  }
];
